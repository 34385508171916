export const storage = {
  /**
   * Уникальный рандомный ключ, по которому backend идентифицирует пользователя и агрегирует запросы
   */
  uniqDeviceID: "v1_udi",
  /**
   * Ключи авторизации
   */
  jwt: "v1_at",
  /**
   * Ключ аутентификации пользователя (нужен для OTP и регистрации), хранится в SessionStorage
   */
  magnitIDCode: "v1_uic",
  /**
   * Параметры входа логина для обработки перезагрузок
   */
  savedOTPData: "v1_sod",
  /**
   * Храним идентификатор сессии для kfp
   */
  ksid: "v1_ksid",
  /**
   * Храним флаг принятия политики cookie
   */
  cookie: "v1_uac",
  /**
   * Храним статус, что пользователь недозарегистрирован
   */
  incompleteProfile: "v1_icp",
  /**
   * Храним последний qrcode пользователя
   */
  qrcode: "v1_uqc",
  /**
   * Храним последнее состояние бонусных программ
   */
  bonuses: "v1_ubn",
  /**
   * информация о том, что пользователь имеет доступ granted
   */
  // :TODO временное решение для пушей
  pushPermissionGranted: "v1_upg",
  /**
   * Храним токен, полученный при подписке на пуши
   */
  pushSubscription: "v1_ups",
  /**
   * Храним дату обновления токена, полученного при подписке на пуши (мс)
   */
  pushSubscriptionUpdated: "v1_upu",
  /**
   * Сохранение последнего отправленного серверу состояния системного разрешения показа уведомлений
   */
  lastPushPermissionState: "v1_lps",
  /**
   * Храним UUID пользователя
   */
  uuid: "v1_uid",

  /**
   * Храним флаг, что магазин выбран/подтвержден
   */
  storePrompted: "v1_sp",

  /**
   * Храним id выбранного магазина
   */
  selectedStoreCode: "v1_scode",

  /**
   * Храним id выбранного города
   */
  selectedCityId: "v1_cid",
};

export const APP_EMAIL = "info@v1.market";
export const APP_PHONE = "8 (800) 200-84-74";
export const APP_TELEGRAMM_BOT = "@V1_official_Bot";
export const APP_TELEGRAMM_BOT_URL = `https://t.me/${APP_TELEGRAMM_BOT.substring(1)}`;
export const USER_CARDS_LIMIT = 5;

export const devToggles = {
  newCoupons: "v1dt_nc",
};

export const FETCH_STORES_DEBOUNCE = 500;
