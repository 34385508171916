<script setup lang="ts">
import { onMounted, watch, onBeforeMount } from "vue";
import Cookie from "@magnit/core/src/utilities/cookie";
import { provideKeys } from "@magnit/unit-catalog/src/unit-catalog.vars";
import usePwa from "~/composables/usePwa";
import useAnalytics from "~/composables/useAnalytics";
import { useAuthStore } from "~/store/auth";
import { useOTPStore } from "~/store/otp";
import { useUserStore } from "~/store/user";
import { usePushStore } from "~/store/push";
import { loadScript } from "~/utils/etc";
import { storage } from "~/utils/consts";
import useFirebaseInit from "~/composables/useFirebaseInit";
import { usePlatformStore } from "~/store/platform";
import { usePwaStore } from "~/store/pwa";

const config = useRuntimeConfig();
const authStore = useAuthStore();
const otpStore = useOTPStore();
const pushStore = usePushStore();
const userStore = useUserStore();
const pwaStore = usePwaStore();
const platformStore = usePlatformStore();
const storesStore = useStoresStore();

authStore.$onAction(({ name }) => {
  if (name === "logout") {
    otpStore.$reset();
    authStore.$reset();
    userStore.$reset();

    userStore.clearSavedUUID();
  }
});

usePwa();
useAnalytics();
useFirebaseInit();

storesStore.restoreSelectedStore();

onBeforeMount(() => {
  const JWT = Cookie.get(storage.jwt, true);

  if (JWT) {
    const expiration = getJWTExpiration(JWT.access);
    const hasInitialAuth = expiration ? !isJWTExpired(expiration) : false;
    authStore.setAuth(hasInitialAuth);
  }
});

onMounted(async () => {
  const platformInfo = getPlatformInfo();
  if (platformInfo) {
    platformStore.setValues(platformInfo);
    pwaStore.state.showIosTutorial =
      !platformInfo.pwa && platformInfo.osName === "ios";
  }
  loadScript(`/scripts/${config.public.kfpCid}.das.obf.js`);
  nextTick(async () => {
    await pushStore.updateToken();
  });
});

watch(
  () => authStore.state.authorized,
  async (authorized) => {
    if (authorized) {
      await userStore.getBaseProfileInfo();
    }
  },
  { immediate: true },
);

/**
 * Каталог только в авторизованной зоне, поэтому знаем, что пользователю 18+
 */
provide(provideKeys.approved, ref(true));
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
