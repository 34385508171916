import { ref, reactive, computed } from "vue";
import { defineStore } from "pinia";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, type Messaging } from "firebase/messaging";
import { useAnalyticManager } from "@magnit/analytic-events";
import { useLocalStorage } from "@vueuse/core";
import { HttpCodes, urls } from "~/api/config";
import { useTransport } from "~/composables/useTransport";
import { useUserStore } from "~/store/user";
import { storage } from "~/utils/consts";
import useNotification from "~/composables/useNotification";
import serviceWorkerInit from "~/sw";

interface IPushStatus {
  sendToken: "initial" | "pending" | "success" | "error";
  permissions: "initial" | "pending" | "success" | "error";
}

export const fbaseConfig = {
  apiKey: "AIzaSyC9yWIqwnqAjRkGT62W961y8ahTUNFl-yI",
  authDomain: "v1market.firebaseapp.com",
  projectId: "v1market",
  storageBucket: "v1market.appspot.com",
  messagingSenderId: "1047100138086",
  appId: "1:1047100138086:web:8203cf776fd8d5f49783a7",
  measurementId: "G-C9CKNFKL5T",
};

const VAPID_KEY =
  "BKQngmaW3aunF5TcoP4Lsx-bFFeK1uawkrDhIkI89cbyM3SBMUgcp1NmSpk-OiGVRVwVCl9OqireOj5VvQ8XYvc";

const CHECK_PUSH_TOKEN_INTERVAL_MS = 60 * 60 * 24 * 7 * 1000;

export const usePushStore = defineStore("push", () => {
  const userStore = useUserStore();
  const { send } = useAnalyticManager();
  const { warning } = useNotification();

  const fbm = ref<Messaging>();
  const status = reactive<IPushStatus>({
    sendToken: "initial",
    permissions: "initial",
  });

  const savedSubscription = useLocalStorage<string>(
    storage.pushSubscription,
    "",
  );
  const savedSubscriptionUpdated = useLocalStorage<string>(
    storage.pushSubscriptionUpdated,
    "",
  );
  const lastPermissionsState = useLocalStorage(
    storage.lastPushPermissionState,
    "",
  );

  watch(
    () => userStore.permissions.isPushPermitted,
    (next) => {
      if (userStore.status.permissions === "success") {
        if (next) {
          getFBMApi();
        }
      }
    },
  );

  async function enrollDevice(token: string, hasPermission: boolean) {
    status.sendToken = "pending";

    const { data, error } = await useTransport(urls.user.enrollDevice, {
      method: "POST",
      body: {
        pushToken: token,
        notificationsPermitted: hasPermission,
      },
      permissions: { jwt: true },
    });

    if (data.value !== null) {
      status.sendToken = "success";
      if (hasPermission) {
        savedSubscription.value = token;
        await userStore.updatePermissions(true, "isPushPermitted");
      }
    }

    if (error.value) {
      if (
        error.value?.statusCode &&
        error.value.statusCode > HttpCodes.Error4xx
      ) {
        status.sendToken = "error";

        warning({
          text: "Не удалось включить уведомления. Попробуйте ещё раз",
        });
      }
    }
  }

  const getFBMApi = (): Messaging => {
    if (fbm.value) {
      return fbm.value;
    }
    const fb = initializeApp(fbaseConfig);
    fbm.value = getMessaging(fb);
    return fbm.value;
  };

  const requestPermission = async (refresh?: boolean) => {
    status.permissions = "pending";
    try {
      const permission = refresh ? Notification.permission : await Notification.requestPermission();
      if (!refresh) {
        send("Profile:PushOn:RequestPermissions");
      }
      const registration = await navigator.serviceWorker.getRegistration();
      if (!registration) {
        await serviceWorkerInit();
      }
      const api = getFBMApi();
      const currentToken = permission === "granted"
        ? await getToken(api, {
          serviceWorkerRegistration: registration,
          vapidKey: VAPID_KEY,
        })
        : savedSubscription.value;

      if (currentToken) {
        try {
          const updatedToken = currentToken !== savedSubscription.value;
          if (updatedToken || permission !== lastPermissionsState.value) {
            await enrollDevice(currentToken, permission === "granted");
            lastPermissionsState.value = permission;
          }
          if (updatedToken || !savedSubscriptionUpdated.value) {
            savedSubscriptionUpdated.value = String(Date.now());
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log("An error occurred while retrieving token. ", err);
        }
      } else {
        // Show permission request.
        // eslint-disable-next-line no-console
        console.log(
          "No registration token available. Request permission to generate one.",
        );
      }
      switch (permission) {
        case "granted":
          send("Profile:PushOn:Toast:Granted");
          break;
        case "denied":
          send("Profile:PushOn:Toast:Denied");
          break;
        default:
          send("Profile:PushOn:Toast:Default");
      }
    } catch (e) {
      status.permissions = "error";
      if (!refresh) {
        warning({
          text: "Не удалось включить уведомления. Попробуйте ещё раз",
        });
      }
    }
  };

  const updateToken = async () => {
    if (typeof window === "undefined") {
      return;
    }
    if (savedSubscription.value &&
      (!savedSubscriptionUpdated.value ||
        (Date.now() - CHECK_PUSH_TOKEN_INTERVAL_MS > Number(savedSubscriptionUpdated.value)) ||
        Notification.permission !== lastPermissionsState.value)) {
      await requestPermission(true);
    }
  };

  return {
    status,
    hasToken: computed(() => Boolean(savedSubscription.value)),
    requestPermission,
    updateToken,
    enrollDevice,
  };
});
