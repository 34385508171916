import { type IAnalyticEventsConfig } from "@magnit/analytic-events/src/manager";

export default <IAnalyticEventsConfig>{
  "Main:Stars:View": {
    ya: {
      eventName: "todayMain_stars_view",
      page: "todayMain",
      block: "stars",
    },
  },
  "Main:Qr:View": {
    ya: {
      eventName: "todayMain_qr_view",
      page: "todayMain",
      block: "qr",
    },
  },
  "Main:Map:View": {
    ya: {
      eventName: "todayMain_map_view",
      page: "todayMain",
      block: "map",
    },
  },
  "Main:Map:Click": {
    ya: {
      eventName: "todayMain_map_openMap_click",
      page: "todayMain",
      block: "map",
      element: "openMap",
    },
  },
  "Main:Checks:View": {
    ya: {
      eventName: "todayMain_checks_view",
      page: "todayMain",
      block: "checks",
    },
  },
  "Main:Checks:Click": {
    ya: {
      eventName: "todayMain_checks_click",
      page: "todayMain",
      block: "checks",
    },
  },
  "Main:Notifications:View": {
    ya: {
      eventName: "todayMain_notice_view",
      page: "todayMain",
      block: "notice",
    },
  },
  "Main:Notifications:Click": {
    ya: {
      eventName: "todayMain_notice_click",
      page: "todayMain",
      block: "notice",
    },
  },
  "Main:AdFox:View": {
    ya: {
      eventName: "todayMain_banner_view",
      page: "todayMain",
      block: "banner",
    },
  },
  "Main:AdFox:Click": {
    ya: {
      eventName: "todayMain_banner_click",
      page: "todayMain",
      block: "banner",
    },
  },
  "Main:MyCoupons:View": {
    ya: {
      eventName: "todayMain_myCoupons_view",
      page: "todayMain",
      block: "myCoupons",
    },
  },
  "Main:MyCoupons:Item:View": {
    ya: {
      eventName: "todayMain_myCoupons_item_view",
      page: "todayMain",
      block: "myCoupons",
      element: "item",
    },
  },
  "Main:MyCoupons:Item:Click": {
    ya: {
      eventName: "todayMain_myCoupons_item_click",
      page: "todayMain",
      block: "myCoupons",
      element: "item",
    },
  },
  "Main:MyCoupons:Item:Favorite:Click": {
    ya: {
      eventName: "todayMain_myCoupons_like_click",
      page: "todayMain",
      block: "myCoupons",
      element: "like",
    },
  },
  "Main:MyCoupons:All:Click": {
    ya: {
      eventName: "todayMain_myCoupons_all_click",
      page: "todayMain",
      block: "myCoupons",
      element: "all",
    },
  },
  "Main:MyCoupons:AllCp:Click": {
    ya: {
      eventName: "todayMain_myCoupons_allCp_click",
      page: "todayMain",
      block: "myCoupons",
      element: "allCp",
    },
  },
  "Main:MyCoupons:Empty:Click": {
    ya: {
      eventName: "todayMain_myCoupons_showCoupons_click",
      page: "todayMain",
      block: "myCoupons",
      element: "showCoupons",
    },
  },
  "Main:Coupons:View": {
    ya: {
      eventName: "todayMain_coupons_view",
      page: "todayMain",
      block: "coupons",
    },
  },
  "Main:Coupons:Item:View": {
    ya: {
      eventName: "todayMain_coupons_item_view",
      page: "todayMain",
      block: "coupons",
      element: "item",
    },
  },
  "Main:Coupons:Item:Click": {
    ya: {
      eventName: "todayMain_coupons_item_click",
      page: "todayMain",
      block: "coupons",
      element: "item",
    },
  },
  "Main:Coupons:Item:Favorite:Click": {
    ya: {
      eventName: "todayMain_coupons_like_click",
      page: "todayMain",
      block: "coupons",
      element: "like",
    },
  },
  "Main:Coupons:All:Click": {
    ya: {
      eventName: "todayMain_coupons_all_click",
      page: "todayMain",
      block: "coupons",
      element: "all",
    },
  },
  "Main:Coupons:AllCp:Click": {
    ya: {
      eventName: "todayMain_coupons_allCp_click",
      page: "todayMain",
      block: "coupons",
      element: "allCp",
    },
  },
};
