enum HttpCodes {
  Success = 200,
  NoContent = 204,

  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,

  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Error4xx = 400,
  Error5xx = 500,
}

enum ErrorCodes {
  Captcha = "CaptchaError",
  CardMergeBlocked = "cardMergeBlocked",
  IncompleteProfile = "incompleteProfile",
  EmailForbidden = "emailForbiddenToUse",
  ValidationError = "validationError",
}

/**
 * @swagger web-gateway: https://web-gateway.dev.ya.magnit.ru/docs/index.html
 * @swagger magnit-id: https://id.dev.ya.magnit.ru/docs/index.html
 */
const urls = {
  "otp": {
    /**
     * Генерация и отправка OTP кода по номеру телефона
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDSendOTPV1
     */
    getCode: "/v1/auth/otp",
    /**
     * Проверка OTP и получение MagnitID Code кода для авторизации нового пользователя
     * @link: hhttps://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDCheckOTPV1
     */
    checkCode: "/v1/auth/otp/check",
  },
  "auth": {
    /**
     * Авторизация
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDGenerateTokenV1
     */
    login: "/v1/auth/token",
    /**
     * Обновление JWT токена
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/authorization/MagnitIDRefreshTokenV1
     */
    refresh: "/v1/auth/token/refresh",
  },
  "user": {
    /**
     * Получаем информацию для отрисовки QR-кода
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/post_v2_totp
     */
    qrcode: "/v2/totp",
    /**
     * Get user balance information
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/balances-v2/balancesV2GetV3UserBalance
     */
    balance: "/v3/user/balance",
    /**
     * Получаем карточки пользователя
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/get_v2_user_cards
     */
    cards: "/v2/user/cards",
    /**
     * Смерживаем вертуальную и физическую карты
     * @link: https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cards-v2/post_v3_user_card_merge
     */
    cardMerge: "/v3/user/card/merge",
    /**
     * Подтверждение e-mail
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDEmailConfirmV1
     */
    emailConfirmation: "/v1/profile/email-confirm",
    /**
     * Регистрация нового пользователя
     * @link: https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDRegisterProfileByMagnitIDCodeV1
     */
    register: "/v3/profile/register/magnit-id-code",
    /**
     * Получаем профиль пользователя или обноваляем (patch)
     * https://id.dev.ya.magnit.ru/docs/index.html#/profiles/MagnitIDGetProfileV1
     */
    profile: "/v1/profile",
    /**
     * Разрешения пользователя на получение sms, email, чеков на email.
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/profiles/get_v1_profile_permissions
     */
    permissions: "/v2/profile/permissions",
    /**
     * Сохраняем push-токен и передаем состояние системного разрешения на показ уведомлений на сервер Cuscom
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/cuscom/post_v1_cuscom_enroll_device
     */
    enrollDevice: "/v1/cuscom/enroll-device",
  },
  "transactions": {
    /**
     * Получение списка транзакций
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/transactions/get_v1_user_transactions
     */
    list: "/v1/user/transactions",
    /**
     * Получение транзакции по ID
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/transactions/get_v1_user_transactions__transactionId_
     */
    item: "/v1/user/transactions",
  },
  "geohelper": {
    /**
     * Поиск магазина по координатам(широта и долгота).
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_geolocation_store
     */
    storesByCoords: "/v1/geolocation/store",
    /**
     * Поиск города по id
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/geohelper/get_v1_cities_city
     */
    city: "/v1/cities/city",
    /**
     * Получаем список городов, в которых есть магазины
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/geohelper/get_v1_cities
     */
    cities: "/v1/cities",
    /**
     * Поиск магазина по ID или Code. В запросе требуется передать только один из параметров.
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/geohelper/get_v1_stores_store
     */
    store: "/v1/stores/store",
    /**
     * Получаем список магазинов в городе
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/geohelper/get_v1_stores
     */
    stores: "/v1/stores",
  },
  "content": {
    // https://web-gateway.dev.ya.magnit.ru/docs/index.html#/v1.market/get_v1_v1_market_content_main_page
    mainPageBanners: "/v1/v1-market/content/main-page",
  },
  "coupons": {
    /**
     * Получение списка купонов
     * https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/coupons/get_v1_coupons_v1_market
     */
    list: "/v1/coupons",
    listN: "/v1/coupons/v1-market",
    /**
     * Активация купона
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/coupons/post_v1_coupons_activate
     */
    activate: "/v1/coupons/activate",
    /**
     * Добавление купона в список любимых
     * https://web-gateway.dev.ya.magnit.ru/docs/index.html#/coupons/post_v1_coupons_favorite
     */
    favorite: "/v1/coupons/favorite",
  },

  "goods": {
    /**
     * Поиск товаров по категориям или по поисковой строке (обновленный контракт с поддержкой Маркета)
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV2GoodsSearch
     */
    products: "/v2/goods/search",
    /**
     * Получаем информации о товаре
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV2GoodsByGoodIdStoresByStoreCode
     */
    product: "/v2/goods/",
    /**
     * Получение дерева категорий.
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffGetV3CategoriesStoreByStoreCode
     */
    categories: "/v3/categories/store",
    /**
     * Получение фильтров для товарного каталога
     * @link: https://omni-web.k8s.dev.mos.corp/docs#/catalog-bff/catalogBffPostV1GoodsFilters
     */
    filters: "/v1/goods/filters",
  },

  "userReviews": {
    /**
     * Получить анкету для пользователей по ID анкеты
     * @link: https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/user-reviews/get_v2_user_reviews_poll__pollId_
     */
    poll: "/v2/user-reviews/poll",
    /**
     * Сохранить фидбэк (ответы на анкету)
     * @link: https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/user-reviews/post_v2_user_reviews_object_feedback
     */
    saveFeedback: "/v2/user-reviews/object/feedback",
  },

  "store-search": {
    /** Получение списка магазинов по поисковой строке.
     * @link https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/storesearch/post_v1_store_search_address
     */
    address: "/v1/store-search/address",
    /** Получение списка магазинов по геолокации.
     * @link https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/storesearch/post_v1_store_search_geo
     */
    geo: "/v1/store-search/geo",
    /** Получение магазина по его коду.
     * @link https://web-gateway-debug.k8s.dev.mos.corp/docs/index.html#/storesearch/post_v1_store_search_geo
     */
    store: "/v1/store-search",
  },
};

export { urls, HttpCodes, ErrorCodes };
